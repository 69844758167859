<template>
  <!-- 新增/编辑 对话框 -->
  <el-dialog
    :title="this.editType === 1 ? '新增类型' : '编辑类型'"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close='onClose'
  >
    <el-form ref="paramsFormRef" label-width="130px" :model="paramsForm" :rules="rules">
      <el-form-item label="类型名称" prop="value">
        <el-input v-model.trim="paramsForm.value" placeholder="请输入类型名称"></el-input>
      </el-form-item>
      <el-form-item label="类型编码" prop="code">
        <el-input v-model.trim="paramsForm.code" placeholder="请输入类型编码"></el-input>
      </el-form-item>
      <el-form-item label="是否是系统字典" prop="sysDict">
        <el-radio-group v-model="paramsForm.sysDict">
          <el-radio label="Y">是</el-radio>
          <el-radio label="N">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model.trim="paramsForm.sort" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item label="字典描述">
        <el-input
          type="textarea"
          v-model.trim="paramsForm.remark"
          :rows="4"
          placeholder="请输入描述"
          :maxlength="500"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addDictionary, editDictionary } from '@/api/main/system/dictionaryMana';
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      rules: {
        value: [
          { required: true, message: '请输入类型名称', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入唯一编码', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        sysDict: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      editType: 1,
      paramsForm: {
        id: '',
        value: '',
        code: '',
        sort: '',
        remark: '',
        sysDict: null,
      },
    };
  },
  methods: {
    init(row) {
      this.dialogVisible = true;
      this.editType = row ? 2 : 1 // 1 新增 / 2 编辑
      if (row) {
        this.$nextTick(() => {
          this.paramsForm = Object.assign(this.paramsForm, {
            id: row.id,
            value: row.value,
            code: row.code,
            sort: row.sort,
            remark: row.remark,
            sysDict: row.sysDict,
          });
        });
      }
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
      this.paramsForm.id = '';
      this.paramsForm.sort = '';
      this.paramsForm.remark = '';
      this.editType = null
    },
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;

        let url = ''
        let msg = ''
        this.editType === 1 ? url = addDictionary(this.paramsForm) : url = editDictionary(this.paramsForm)
        this.editType === 1 ? msg = '新增类型成功' : msg = '编辑类型成功'

        url.then(res => {
            this.$message.success(msg);
            this.$emit('back', this.editType);
            this.onClose();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>
