<template>
  <div>
    <div class='title'>字典管理</div>
    <el-form :inline='true' class='demo-form-inline'>
      <el-form-item>
        <el-input v-model.trim='queryParam.value' placeholder='请输入名称/编码'></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model='queryParam.sysDict' placeholder='请选择类型'>
          <el-option label='是' value='Y'></el-option>
          <el-option label='否' value='N'></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model='queryParam.status' placeholder='请选择状态'>
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type='primary' @click='getDictList'>查询</el-button>
        <el-button @click='resetForm'>重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table-box">
      <div class="add-btn">
        <el-button type='primary' icon="el-icon-plus" @click="$refs.addDictForm.init()">新建</el-button>
        <el-button type='danger' :disabled="!this.multipleSelection.length" @click="handleDelete">批量删除</el-button>
      </div>
      <el-table
        :data="tableData"
        stripe
        v-loading="loading"
        size="medium"
        ref="dictionaryTable"
        style='width: 100%'
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          align='center'
          width="60">
        </el-table-column>
        <el-table-column
          prop='value'
          label='类型名称'
          sortable
          align='center'
          width='200'>
        </el-table-column>
        <el-table-column
          prop='code'
          label='类型编码'
          align='center'
          sortable
          width='200'>
        </el-table-column>
        <el-table-column
          prop='sysDict'
          align='center'
          width='180'
          label='是否是系统字典'>
          <template slot-scope='{ row }'>{{ row.sysDict === 'Y' ? '是' : '否'}}</template>
        </el-table-column>
        <el-table-column
          prop='remark'
          sortable
          width='220'
          align='center'
          label='字典描述'>
          <template slot-scope='{ row }'>{{ row.remark || "--"}}</template>
        </el-table-column>
        <el-table-column
          prop='status'
          sortable
          align='center'
          label='状态'>
          <template slot-scope='{ row }'>{{row.status === 0 ? '正常' : row.status === 1 ? '停用' : '删除'}}</template>
        </el-table-column>
        <el-table-column
          prop='createTime'
          sortable
          width='200'
          align='center'
          label='添加时间'>
        </el-table-column>
        <el-table-column
          fixed='right'
          label='操作'
          align='center'
          width='140'>
          <template slot-scope='{ row }'>
            <div class='comm-operation'>
              <span class="edit-btn mr-16" @click="$refs.addDictForm.init(row)">修改</span>
              <span class="delete-btn" @click="deleteThis(row.id)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class='pagination'>
        <el-pagination
          background
          :total='total'
          :current-page="queryParam.pageNo"
          :page-size="queryParam.pageSize"
          @size-change='handleSizeChange'
          @current-change='handleCurrentChange'
          :page-sizes='[10, 20, 30, 50]'
          layout='total, prev, pager, next, sizes'>
        </el-pagination>
      </div>
    </div>
    <addDictForm ref="addDictForm" @back="handleBack"/>
  </div>
</template>

<script>
import addDictForm from '@/views/main/basicPage/system/dictionaryMana/addDictForm';
import {getDictList, delDictionary} from '@/api/main/system/dictionaryMana'
export default {
  data() {
    return {
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        value: '',
        sysDict: null,
        status: null,
      },
      total: 0,
      loading: false,
      tableData: [],
      statusList: [
        {id:0,name:'正常'},
        {id:1,name:'停用'},
        {id:2,name:'删除'}
      ],
      multipleSelection: [],
    }
  },
  components: {addDictForm},
  created() {
    this.getDictList()
  },
  methods: {
    // 获取字典列表
    getDictList() {
      this.loading = true
      getDictList(this.queryParam).then(res => {
        this.tableData = res.data.rows
        this.total = res.data.totalRows
      }).finally(() => {
        this.loading = false
      })
    },
    // 重置
    resetForm() {
      this.queryParam = {
        pageNo: 1,
        pageSize: 10,
        value: '',
        sysDict: '',
        status: '',
      }
      this.getDictList()
    },
    handleSizeChange(size) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = size
      this.getDictList()
    },
    handleCurrentChange(page) {
      this.queryParam.pageNo = page
      this.getDictList()
    },
    // 批量删除
    handleDelete() {
      const ids = this.multipleSelection.map(item => item.id).join(',');
      this.deleteThis(ids);
    },
    // 删除
    deleteThis(ids) {
      this.$confirm('此操作将永久删除该类型, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delDictionary({ ids }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getDictList();
          });
        })
        .catch(() => {});
    },
    handleBack(type) {
      if(type === 1) {
        // 新增 回首页
        this.queryParam.pageNo = 1
      }
      this.getDictList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    }
  }
};
</script>

<style lang='less' scoped>
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #E8E8E8;
}
.demo-form-inline{
  padding-top: 16px;
  border-bottom: 1px solid #E8E8E8;
}
.table-box{
  padding-top: 24px;
}
.add-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}
.pagination {
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
