import { axios } from '@/utils/request';
/**
 * 分页查询
 */
export function getDictList(parameter) {
  return axios({
    url: '/sysDictData/page',
    method: 'get',
    params: parameter
  });
}
/**
 * 新增
 */
export function addDictionary(parameter) {
  return axios({
    url: '/sysDictData/add',
    method: 'post',
    data: parameter
  });
}
/**
 * 编辑
 */
export function editDictionary(parameter) {
  return axios({
    url: '/sysDictData/edit',
    method: 'post',
    data: parameter
  });
}
/**
 * 删除
 */
export function delDictionary(parameter) {
  return axios({
    url: '/sysDictData/delete',
    method: 'post',
    data: parameter
  });
}
/**
 * 获取详情
 */
export function getDictDetail(parameter) {
  return axios({
    url: '/sysDictData/detail',
    method: 'get',
    params: parameter
  });
}
